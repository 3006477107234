/* =======================================================================
Layout
========================================================================== */

.max-width-1 { max-width: $max-width-1; }
.max-width-2 { max-width: $max-width-2; }
.max-width-3 { max-width: $max-width-3; }
.max-width-4 { max-width: $max-width-4; }

.container {
  margin: 0 auto;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.z-1 { z-index: $z-index-1; }
.z-2 { z-index: $z-index-2; }
.z-3 { z-index: $z-index-3; }

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
