.c-contact {
  display: inline-block;
  margin: 4em 4em;
  vertical-align: top;

  &__icon {
    background: $color-white;
    background: linear-gradient(to bottom, #ffffff 0%,#d6d4d4 100%);
    box-shadow: 0 3px 15px 0 rgba($color-black, .65);
    border-radius: 100%;
    width: 132px;
    height: 132px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: transform $transition-duration $transition-easing;

    svg {
      width: 48px;
    }
  }

  &__title {
    color: $color-red;
    font-family: $font-stack-alt-2;
    font-size: $font-size-5;
    display: block;
    margin-top: -45px;
  }

  &:hover &__icon {
    transform: translateY(-25%);
  }
}
