.text {
  // Colors
  &-black { color: $color-black; }
  &-white { color: $color-white; }
  &-gray  { color: $color-gray; }
  &-red   { color: $color-red; }

  // Style
  &-inclined {
    display: inline-block;
    transform: rotate(-7deg) skewX(-10deg);
  }

  &-separator:after {
    content: '';
    background-image: url(../images/separator-title.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 107px;
    height: 9px;
    display: block;
    margin: -.28em 0 0 40px;

    @include mq('sm') {
      width: 70px;
    }
  }

  &-shadow {
    text-shadow: -1px 0 12px rgba($color-black, .5);
  }

  &-underline {
    text-decoration: underline;
  }

  &-alt {
    font-family: $font-stack-alt;
    text-transform: uppercase;
    line-height: 1;
  }

  &-alt-2 {
    font-family: $font-stack-alt-2;
    text-transform: none;
    letter-spacing: -1px;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }
}

h1, .h1 { font-size: $font-size-2; }
h2, .h2 { font-size: $font-size-3; }
h3, .h3 { font-size: $font-size-4; }
h4, .h4 { font-size: $font-size-6; }
h5, .h5 { font-size: $font-size-8; }
h6, .h6 { font-size: $font-size-9; }

h2.text-alt-2, .h2.text-alt-2 {
  font-size: $font-size-1;
}

h3.text-alt-2, .h3.text-alt-2 {
  font-size: $font-size-3;
}

h4.text-alt-2, .h4.text-alt-2 {
  font-size: $font-size-5;
}

h5.text-alt-2, .h5.text-alt-2 {
  font-size: $font-size-7;
}

@include mq('sm') {
  h1, .h1 { font-size: 55px; }
  h3, .h3 { font-size: 40px; }

  h2.text-alt-2, .h2.text-alt-2 {
    font-size: 52px;
  }

  h3.text-alt-2, .h3.text-alt-2 {
    font-size: 50px;
  }

  h4.text-alt-2, .h4.text-alt-2 {
    font-size: 40px;
  }
}
