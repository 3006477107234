.c-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: transform 300ms linear;
  will-change: transform;

  &__links {
    a {
      margin: 0 1.5em;
      display: inline-block;
      color: $color-link;
    	font-family: $font-stack-alt;
    	text-transform: uppercase;
      position: relative;
      transition: none;
    }

    &--icon {
      svg {
        fill: $color-red;
        height: 33px;
        position: absolute;
        left: 50%;
        bottom: -30px;
        opacity: 0;
        transform: translate(-50%, 50%);
        transition: all 200ms $transition-easing;
      }
    }
  }

  &__links a:hover &__links-icon {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &__social {
    a {
      color: $color-gray;
      margin: 0 .6em;
      vertical-align: middle;

      &:hover {
        color: $color-white;
      }
    }

    &--large a {
      font-size: $font-size-8;
      margin: 0 1em;
    }
  }

  &__hamburger {
    border: 1px solid $color-white;
    border-left: 0;
    border-right: 0;
    position: relative;
    vertical-align: middle;
    width: 20px;
    height: 11px;
    display: none;
    margin-left: 10px;

    @include mq('sm') {
      display: inline-block;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 100%;
      height: 1px;
      background: $color-white;
    }
  }

  &__close {
    position: absolute;
    width: 21px;
    height: 21px;
    top: 5.5rem;
    right: 1.5rem;
    display: block;
    transform: rotate(45deg);

    &:after, &:before {
      content: '';
      position: absolute;
      background: $color-white;
    }

    &:after {
      width: 100%;
      height: 1px;
      top: 10px;
    }

    &:before {
      height: 100%;
      width: 1px;
      left: 10px;
    }
  }

  &--mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black;
    background-image: url('../images/background-navigation-mobile.jpg');
    background-size: cover;
    z-index: $z-index-3;
    display: flex;
    padding-top: 3em;
    flex-direction: column;
  }

  &__links--mobile a  {
    font-size: 25px;
    width: 100%;
    padding: .86em 0;
    display: block;
    margin: 0;
    text-align: center;
  }

  &__footer {
    display: flex;
    border-top: 1px solid $color-border;
    width: 100%;
    text-align: center;
    height: 80px;

    .col {
      padding: 1.5em 0;
      height: 100%;
    }

    .col + .col {
      border-left: 1px solid $color-border;
    }
  }
}
