.col {
  float: left;
  box-sizing: border-box;
}

@for $i from 1 through 12  {
  .col-#{$i} {
    width: percentage($i / 12);
  }
}

@each $mq in map-keys($breakpoints) {
  @include mq($mq) {
    @for $i from 1 through 12  {
      .#{$mq}-col-#{$i} {
        width: percentage($i / 12);
      }
    }
  }
}
