.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;

  &--intro {
    min-height: 100%;
    background-image: url('../images/background-header.jpg');
    background-size: cover;
  }

  &--logo {
    background-image: url('../images/svg/background-logo.svg');
    background-position: center;
    background-size: 95% auto;
  }

  &--overlay {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(ellipse at center, rgba(13,10,9,0) 0%, rgba(13,10,9,1) 100%);
    }

    &:hover:after {
      background-color: rgba(13,10,9,0.6);
      background-image: radial-gradient(ellipse at center, rgba(13,10,9,0) 0%, rgba(13,10,9,1) 100%);
    }
  }

  &--center {
    background-position: center;
  }
}
