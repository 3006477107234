.c-footer {
  background-color: rgba($color-black, .8);
  padding: 2.25em 0;
  margin: 5em 0 -5em 0;

  &__cc {
    &-logo {
      fill: $color-red;
      width: 2em;
      height: 2em;
      vertical-align: middle;
      display: inline-block;
      margin-left: .5em;
    }
  }

  .c-navigation__social:first-of-type {
    margin-left: 0;
  }
}
