/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@import url("//hello.myfonts.net/count/3a2059");
@font-face {
  font-family: 'braxton-regular';
  src: url("../fonts/braxton/3A2059_0_0.eot");
  src: url("../fonts/braxton/3A2059_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/braxton/3A2059_0_0.woff2") format("woff2"), url("../fonts/braxton/3A2059_0_0.woff") format("woff"), url("../fonts/braxton/3A2059_0_0.ttf") format("truetype");
}

@font-face {
  font-family: 'oswald-regular';
  src: url("../fonts/oswald/oswald-regular.ttf") format("truetype"), url("../fonts/oswald/oswald-regular.woff") format("woff"), url("../fonts/oswald/oswald-regular.woff2") format("woff2"), url("../fonts/oswald/oswald-regular.eot#iefix");
}

@font-face {
  font-family: 'oswald-light';
  src: url("../fonts/oswald/oswald-light.ttf") format("truetype"), url("../fonts/oswald/oswald-light.woff") format("woff"), url("../fonts/oswald/oswald-light.woff2") format("woff2"), url("../fonts/oswald/oswald-light.eot#iefix");
}

@font-face {
  font-family: 'quattrocento-regular';
  src: url("../fonts/quattrocento/quattrocento-regular.ttf") format("truetype"), url("../fonts/quattrocento/quattrocento-regular.woff") format("woff"), url("../fonts/quattrocento/quattrocento-regular.woff2") format("woff2"), url("../fonts/quattrocento/quattrocento-regular.eot#iefix");
}

@font-face {
  font-family: 'quattrocento-bold';
  src: url("../fonts/quattrocento/quattrocento-bold.ttf") format("truetype"), url("../fonts/quattrocento/quattrocento-bold.woff") format("woff"), url("../fonts/quattrocento/quattrocento-bold.woff2") format("woff2"), url("../fonts/quattrocento/quattrocento-bold.eot#iefix");
}

/* @font-face {
	font-family: 'braxton-regular';
	src: url('../fonts/braxton/braxton-regular.ttf') format("truetype"),
		 	 url('../fonts/braxton/braxton-regular.woff') format("woff"),
		 	 url('../fonts/braxton/braxton-regular.woff2') format("woff2"),
		 	 url('../fonts/braxton/braxton-regular.eot#iefix');
} */
/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-clearfix:before, .separator:before,
.h-clearfix:after,
.separator:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-clearfix:after, .separator:after {
  clear: both;
}

/* line 19, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-clearfix, .separator {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* line 72, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-align-middle {
  vertical-align: middle;
}

/* line 76, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-overflow-hidden {
  overflow: hidden;
}

/* line 80, public/wp-content/themes/carcasse/assets/src/scss/core/_helpers.scss */
.h-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
html {
  font-size: 14px;
}

/* line 5, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
html, body {
  height: 100%;
}

/* line 9, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
body {
  color: #B3B2B8;
  margin: 0;
  background-color: #000000;
  line-height: 1.4;
  font-family: "quattrocento-regular", Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 19, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
a {
  text-decoration: none;
  transition: color .15s;
  color: #FFFFFF;
}

/* line 24, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
a:hover {
  color: #FFFFFF;
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
p {
  margin: 0;
  line-height: 2;
}

/* line 34, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* line 40, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
img {
  max-width: 100%;
}

/* line 44, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 48, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: "oswald-regular", Arial, Verdana, sans-serif;
  font-weight: normal;
  margin: 0;
}

/* line 57, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
strong {
  font-family: "quattrocento-bold";
  font-weight: normal;
}

/* line 63, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: #000000;
  color: white;
}

/* line 68, public/wp-content/themes/carcasse/assets/src/scss/global/_base.scss */
::selection {
  background: #000000;
  color: #FFFFFF;
}

/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.max-width-1 {
  max-width: 1140px;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.max-width-2 {
  max-width: 1080px;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.max-width-3 {
  max-width: 880px;
}

/* line 8, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.max-width-4 {
  max-width: 680px;
}

/* line 10, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.container {
  margin: 0 auto;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-sizing: border-box;
}

/* line 17, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.block {
  display: block;
}

/* line 21, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.inline-block {
  display: inline-block;
}

/* line 25, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.z-1 {
  z-index: 100;
}

/* line 26, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.z-2 {
  z-index: 200;
}

/* line 27, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.z-3 {
  z-index: 300;
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/global/_layout.scss */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* =======================================================================
Navigation
========================================================================== */
/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_nav.scss */
.nav a {
  color: #FFFFFF;
  text-decoration: none;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.flex {
  display: flex;
}

/* line 3, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.flex-column {
  flex-direction: column;
}

/* line 4, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.flex-wrap {
  flex-wrap: wrap;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.items-start {
  align-items: flex-start;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.items-end {
  align-items: flex-end;
}

/* line 8, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.items-center {
  align-items: center;
}

/* line 9, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.items-baseline {
  align-items: baseline;
}

/* line 10, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.items-stretch {
  align-items: stretch;
}

/* line 12, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.self-start {
  align-self: flex-start;
}

/* line 13, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.self-end {
  align-self: flex-end;
}

/* line 14, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.self-center {
  align-self: center;
}

/* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.self-baseline {
  align-self: baseline;
}

/* line 16, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.self-stretch {
  align-self: stretch;
}

/* line 18, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.justify-start {
  justify-content: flex-start;
}

/* line 19, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.justify-end {
  justify-content: flex-end;
}

/* line 20, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.justify-center {
  justify-content: center;
}

/* line 21, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.justify-between {
  justify-content: space-between;
}

/* line 22, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.justify-around {
  justify-content: space-around;
}

/* line 24, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-start {
  align-content: flex-start;
}

/* line 25, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-end {
  align-content: flex-end;
}

/* line 26, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-center {
  align-content: center;
}

/* line 27, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-between {
  align-content: space-between;
}

/* line 28, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-around {
  align-content: space-around;
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.content-stretch {
  align-content: stretch;
}

/* line 31, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */
}

/* line 37, public/wp-content/themes/carcasse/assets/src/scss/global/_flex.scss */
.flex-none {
  flex: none;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col {
  float: left;
  box-sizing: border-box;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-1 {
  width: 8.3333333333%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-2 {
  width: 16.6666666667%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-3 {
  width: 25%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-4 {
  width: 33.3333333333%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-5 {
  width: 41.6666666667%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-6 {
  width: 50%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-7 {
  width: 58.3333333333%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-8 {
  width: 66.6666666667%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-9 {
  width: 75%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-10 {
  width: 83.3333333333%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-11 {
  width: 91.6666666667%;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
.col-12 {
  width: 100%;
}

@media (max-width: 479px) {
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-1 {
    width: 8.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-2 {
    width: 16.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-3 {
    width: 25%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-4 {
    width: 33.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-5 {
    width: 41.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-6 {
    width: 50%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-7 {
    width: 58.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-8 {
    width: 66.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-9 {
    width: 75%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-10 {
    width: 83.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-11 {
    width: 91.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xs-col-12 {
    width: 100%;
  }
}

@media (max-width: 839px) {
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-1 {
    width: 8.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-2 {
    width: 16.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-3 {
    width: 25%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-4 {
    width: 33.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-5 {
    width: 41.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-6 {
    width: 50%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-7 {
    width: 58.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-8 {
    width: 66.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-9 {
    width: 75%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-10 {
    width: 83.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-11 {
    width: 91.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .sm-col-12 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-1 {
    width: 8.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-2 {
    width: 16.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-3 {
    width: 25%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-4 {
    width: 33.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-5 {
    width: 41.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-6 {
    width: 50%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-7 {
    width: 58.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-8 {
    width: 66.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-9 {
    width: 75%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-10 {
    width: 83.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-11 {
    width: 91.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .md-col-12 {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-1 {
    width: 8.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-2 {
    width: 16.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-3 {
    width: 25%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-4 {
    width: 33.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-5 {
    width: 41.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-6 {
    width: 50%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-7 {
    width: 58.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-8 {
    width: 66.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-9 {
    width: 75%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-10 {
    width: 83.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-11 {
    width: 91.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .lg-col-12 {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-1 {
    width: 8.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-2 {
    width: 16.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-3 {
    width: 25%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-4 {
    width: 33.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-5 {
    width: 41.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-6 {
    width: 50%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-7 {
    width: 58.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-8 {
    width: 66.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-9 {
    width: 75%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-10 {
    width: 83.3333333333%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-11 {
    width: 91.6666666667%;
  }
  /* line 15, public/wp-content/themes/carcasse/assets/src/scss/global/_grid.scss */
  .xl-col-12 {
    width: 100%;
  }
}

/* line 3, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-black {
  color: #000000;
}

/* line 4, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-white {
  color: #FFFFFF;
}

/* line 5, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-gray {
  color: #B3B2B8;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-red {
  color: #E91F0A;
}

/* line 9, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-inclined {
  display: inline-block;
  transform: rotate(-7deg) skewX(-10deg);
}

/* line 14, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-separator:after {
  content: '';
  background-image: url(../images/separator-title.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 107px;
  height: 9px;
  display: block;
  margin: -.28em 0 0 40px;
}

@media (max-width: 839px) {
  /* line 14, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  .text-separator:after {
    width: 70px;
  }
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-shadow {
  text-shadow: -1px 0 12px rgba(0, 0, 0, 0.5);
}

/* line 33, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-underline {
  text-decoration: underline;
}

/* line 37, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-alt {
  font-family: "oswald-regular", Arial, Verdana, sans-serif;
  text-transform: uppercase;
  line-height: 1;
}

/* line 43, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-alt-2 {
  font-family: "braxton-regular", Georgia, serif;
  text-transform: none;
  letter-spacing: -1px;
}

/* line 49, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-right {
  text-align: right;
}

/* line 53, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-center {
  text-align: center;
}

/* line 57, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
.text-left {
  text-align: left;
}

/* line 62, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h1, .h1 {
  font-size: 86px;
}

/* line 63, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h2, .h2 {
  font-size: 60px;
}

/* line 64, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h3, .h3 {
  font-size: 54px;
}

/* line 65, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h4, .h4 {
  font-size: 30px;
}

/* line 66, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h5, .h5 {
  font-size: 18px;
}

/* line 67, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h6, .h6 {
  font-size: 14px;
}

/* line 69, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h2.text-alt-2, .h2.text-alt-2 {
  font-size: 100px;
}

/* line 73, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h3.text-alt-2, .h3.text-alt-2 {
  font-size: 60px;
}

/* line 77, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h4.text-alt-2, .h4.text-alt-2 {
  font-size: 50px;
}

/* line 81, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
h5.text-alt-2, .h5.text-alt-2 {
  font-size: 45px;
}

@media (max-width: 839px) {
  /* line 86, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  h1, .h1 {
    font-size: 55px;
  }
  /* line 87, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  h3, .h3 {
    font-size: 40px;
  }
  /* line 89, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  h2.text-alt-2, .h2.text-alt-2 {
    font-size: 52px;
  }
  /* line 93, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  h3.text-alt-2, .h3.text-alt-2 {
    font-size: 50px;
  }
  /* line 97, public/wp-content/themes/carcasse/assets/src/scss/global/_text.scss */
  h4.text-alt-2, .h4.text-alt-2 {
    font-size: 40px;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button {
  padding: .85em 1.6em;
  margin: .5em 0;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-family: "oswald-regular", Arial, Verdana, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* line 12, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:before, .button:after {
  content: '';
  border: 1px solid currentColor;
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  transition: inherit;
}

/* line 24, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:before {
  border-bottom: 0;
  top: 0;
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:after {
  border-top: 0;
  bottom: 0;
}

/* line 34, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button-icon--left, .button-icon--right {
  height: 3em;
  fill: currentColor;
  position: absolute;
  top: 50%;
  transition: inherit;
}

/* line 42, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button-icon--left {
  left: 0;
  transform: translate(-80%, -50%);
}

/* line 47, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button-icon--right {
  right: 0;
  transform: translate(80%, -50%);
}

/* line 52, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:hover .button-icon--left {
  transform: translate(-120%, -50%);
}

/* line 56, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:hover .button-icon--right {
  transform: translate(120%, -50%);
}

/* line 61, public/wp-content/themes/carcasse/assets/src/scss/global/_button.scss */
.button:hover:before, .button:hover:after {
  height: 50%;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_row.scss */
.row {
  position: relative;
  padding: 5em 0;
}

/* line 5, public/wp-content/themes/carcasse/assets/src/scss/global/_row.scss */
.row--promotions {
  padding: 10em 0;
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
.hide {
  display: none !important;
}

@media (max-width: 479px) {
  /* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
  .xs-hide {
    display: none !important;
  }
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
.hide {
  display: none !important;
}

@media (max-width: 839px) {
  /* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
  .sm-hide {
    display: none !important;
  }
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
.hide {
  display: none !important;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
  .md-hide {
    display: none !important;
  }
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
.hide {
  display: none !important;
}

@media (max-width: 1199px) {
  /* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
  .lg-hide {
    display: none !important;
  }
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
.hide {
  display: none !important;
}

@media (max-width: 1440px) {
  /* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_hide.scss */
  .xl-hide {
    display: none !important;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover--intro {
  min-height: 100%;
  background-image: url("../images/background-header.jpg");
  background-size: cover;
}

/* line 13, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover--logo {
  background-image: url("../images/svg/background-logo.svg");
  background-position: center;
  background-size: 95% auto;
}

/* line 20, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover--overlay:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(ellipse at center, rgba(13, 10, 9, 0) 0%, #0d0a09 100%);
}

/* line 30, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover--overlay:hover:after {
  background-color: rgba(13, 10, 9, 0.6);
  background-image: radial-gradient(ellipse at center, rgba(13, 10, 9, 0) 0%, #0d0a09 100%);
}

/* line 36, public/wp-content/themes/carcasse/assets/src/scss/global/_cover.scss */
.cover--center {
  background-position: center;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_separator.scss */
.separator {
  background-image: url("../images/separator-section.png");
  width: 100%;
  height: 5px;
  margin: 5rem 0;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 839px) {
  /* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_separator.scss */
  .separator {
    margin: 2rem 0;
  }
}

/* =======================================================================
Opacity
========================================================================== */
/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-1 {
  opacity: 0.1;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-2 {
  opacity: 0.2;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-3 {
  opacity: 0.3;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-4 {
  opacity: 0.4;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-5 {
  opacity: 0.5;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-6 {
  opacity: 0.6;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-7 {
  opacity: 0.7;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-8 {
  opacity: 0.8;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-9 {
  opacity: 0.9;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/global/_opacity.scss */
.opacity-10 {
  opacity: 1;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/global/_icon.scss */
.icon {
  fill: #FFFFFF;
  fill-opacity: .1;
}

/* line 5, public/wp-content/themes/carcasse/assets/src/scss/global/_icon.scss */
.icon--knife {
  width: 5em;
  display: block;
}

/* line 10, public/wp-content/themes/carcasse/assets/src/scss/global/_icon.scss */
.icon--fork {
  vertical-align: top;
  width: 2em;
  margin: -.25em .5em 0 .5em;
}

@media (max-width: 839px) {
  /* line 10, public/wp-content/themes/carcasse/assets/src/scss/global/_icon.scss */
  .icon--fork {
    width: 1.5em;
    margin: -.25em .25em 0;
  }
}

/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: transform 300ms linear;
  will-change: transform;
}

/* line 12, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__links a {
  margin: 0 1.5em;
  display: inline-block;
  color: #FFFFFF;
  font-family: "oswald-regular", Arial, Verdana, sans-serif;
  text-transform: uppercase;
  position: relative;
  transition: none;
}

/* line 23, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__links--icon svg {
  fill: #E91F0A;
  height: 33px;
  position: absolute;
  left: 50%;
  bottom: -30px;
  opacity: 0;
  transform: translate(-50%, 50%);
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* line 36, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__links a:hover .c-navigation__links-icon {
  opacity: 1;
  transform: translate(-50%, 0);
}

/* line 42, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__social a {
  color: #B3B2B8;
  margin: 0 .6em;
  vertical-align: middle;
}

/* line 47, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__social a:hover {
  color: #FFFFFF;
}

/* line 52, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__social--large a {
  font-size: 18px;
  margin: 0 1em;
}

/* line 58, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__hamburger {
  border: 1px solid #FFFFFF;
  border-left: 0;
  border-right: 0;
  position: relative;
  vertical-align: middle;
  width: 20px;
  height: 11px;
  display: none;
  margin-left: 10px;
}

@media (max-width: 839px) {
  /* line 58, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
  .c-navigation__hamburger {
    display: inline-block;
  }
}

/* line 73, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__hamburger:after {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  height: 1px;
  background: #FFFFFF;
}

/* line 84, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__close {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 5.5rem;
  right: 1.5rem;
  display: block;
  transform: rotate(45deg);
}

/* line 93, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__close:after, .c-navigation__close:before {
  content: '';
  position: absolute;
  background: #FFFFFF;
}

/* line 99, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__close:after {
  width: 100%;
  height: 1px;
  top: 10px;
}

/* line 105, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__close:before {
  height: 100%;
  width: 1px;
  left: 10px;
}

/* line 112, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation--mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  background-image: url("../images/background-navigation-mobile.jpg");
  background-size: cover;
  z-index: 300;
  display: flex;
  padding-top: 3em;
  flex-direction: column;
}

/* line 127, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__links--mobile a {
  font-size: 25px;
  width: 100%;
  padding: .86em 0;
  display: block;
  margin: 0;
  text-align: center;
}

/* line 136, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__footer {
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  width: 100%;
  text-align: center;
  height: 80px;
}

/* line 143, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__footer .col {
  padding: 1.5em 0;
  height: 100%;
}

/* line 148, public/wp-content/themes/carcasse/assets/src/scss/component/_c-navigation.scss */
.c-navigation__footer .col + .col {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

/* line 2, public/wp-content/themes/carcasse/assets/src/scss/component/_c-gallery.scss */
.c-gallery img {
  width: 20%;
}

@media (max-width: 839px) {
  /* line 2, public/wp-content/themes/carcasse/assets/src/scss/component/_c-gallery.scss */
  .c-gallery img {
    width: 33.33%;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header {
  padding: 3em 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 300;
}

/* line 9, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header:before {
  content: '';
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 100%);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 150%;
  transition: all 600ms linear;
  opacity: .5;
  pointer-events: none;
}

/* line 24, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header--fixed .c-navigation:not(.c-navigation--mobile) {
  transform: translateY(-2em);
  transition: transform 500ms linear;
}

/* line 29, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header--fixed:before {
  opacity: 1;
}

/* line 34, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header--fixed .c-header__logo {
  background-image: url("../images/svg/logo-compact.svg");
}

/* line 38, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header__logo {
  width: 127px;
  height: 95px;
  margin: 0 4em 1em 4em;
  background-image: url("../images/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
}

@media (max-width: 839px) {
  /* line 38, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
  .c-header__logo {
    width: 100px;
    margin: 0 !important;
  }
}

/* line 56, public/wp-content/themes/carcasse/assets/src/scss/component/_c-header.scss */
.c-header .c-navigation__social a:last-of-type {
  margin-right: 0;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-footer.scss */
.c-footer {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2.25em 0;
  margin: 5em 0 -5em 0;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/component/_c-footer.scss */
.c-footer__cc-logo {
  fill: #E91F0A;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  display: inline-block;
  margin-left: .5em;
}

/* line 17, public/wp-content/themes/carcasse/assets/src/scss/component/_c-footer.scss */
.c-footer .c-navigation__social:first-of-type {
  margin-left: 0;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 7, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro .c-title {
  margin-bottom: 4rem;
}

/* line 10, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro .c-title h1 {
  margin: 0;
}

/* line 14, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro .c-title h2 {
  margin-top: -0.4em;
}

/* line 18, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro .c-title p {
  width: 90%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.75rem;
  color: white;
  line-height: 1.2;
  margin-top: 2rem;
}

/* line 30, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
.c-intro .c-intro__button-col {
  display: flex;
  justify-content: space-evenly;
  gap: 60px;
}

@media (max-width: 840px) {
  /* line 30, public/wp-content/themes/carcasse/assets/src/scss/component/_c-intro.scss */
  .c-intro .c-intro__button-col {
    flex-direction: column;
    gap: 10px;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title {
  position: relative;
}

/* line 4, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--top .text-inclined {
  color: #FFFFFF;
}

/* line 8, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--bottom .text-inclined {
  margin-top: 32px;
  transform: unset;
  color: #FFFFFF;
}

/* line 14, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--resto .text-inclined {
  margin-left: -2.5em;
}

@media (max-width: 839px) {
  /* line 14, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
  .c-title--resto .text-inclined {
    margin-left: 0;
  }
}

/* line 22, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--menu {
  margin-bottom: 3em;
}

/* line 25, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--menu .text-inclined {
  margin-left: -2em;
}

/* line 30, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
.c-title--contact .text-inclined {
  margin-left: -6em;
}

@media (max-width: 839px) {
  /* line 30, public/wp-content/themes/carcasse/assets/src/scss/component/_c-title.scss */
  .c-title--contact .text-inclined {
    margin-left: -3em;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
.c-promotions {
  display: flex;
  text-align: center;
}

/* line 5, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
.c-promotions__item {
  height: 570px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3em;
}

/* line 12, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
.c-promotions__item-description {
  opacity: 0;
  font-size: 0;
  transition: opacity .25s, font-size .25s .25s;
}

@media (max-width: 992px) {
  /* line 12, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
  .c-promotions__item-description {
    opacity: 1;
    font-size: 100%;
  }
}

/* line 23, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
.c-promotions__item:hover .c-promotions__item-description {
  opacity: 1;
  font-size: 100%;
  transition: font-size .25s, opacity .5s .25s;
}

@media (max-width: 992px) {
  /* line 5, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
  .c-promotions__item {
    padding: 5em 3em;
    height: auto;
  }
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-promotions.scss */
  .c-promotions {
    flex-direction: column;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
.c-menu {
  padding: 0 1em;
}

/* line 4, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
.c-menu:first-of-type {
  padding-left: 0;
}

/* line 8, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
.c-menu:last-of-type {
  padding-right: 0;
}

@media (max-width: 839px) {
  /* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
  .c-menu {
    padding: 0;
  }
}

@media (max-width: 839px) {
  /* line 16, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
  .c-menu + .c-menu {
    margin-top: 1.5em;
  }
  /* line 20, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
  .c-menu + .c-menu h4 {
    margin-top: 1.5em;
  }
}

/* line 26, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
.c-menu li + li {
  margin-top: 2em;
}

/* line 30, public/wp-content/themes/carcasse/assets/src/scss/component/_c-menu.scss */
.c-menu > .button {
  margin-top: 2rem;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-contact.scss */
.c-contact {
  display: inline-block;
  margin: 4em 4em;
  vertical-align: top;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/component/_c-contact.scss */
.c-contact__icon {
  background: #FFFFFF;
  background: linear-gradient(to bottom, #ffffff 0%, #d6d4d4 100%);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.65);
  border-radius: 100%;
  width: 132px;
  height: 132px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  transition: transform 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* line 19, public/wp-content/themes/carcasse/assets/src/scss/component/_c-contact.scss */
.c-contact__icon svg {
  width: 48px;
}

/* line 24, public/wp-content/themes/carcasse/assets/src/scss/component/_c-contact.scss */
.c-contact__title {
  color: #E91F0A;
  font-family: "braxton-regular", Georgia, serif;
  font-size: 50px;
  display: block;
  margin-top: -45px;
}

/* line 32, public/wp-content/themes/carcasse/assets/src/scss/component/_c-contact.scss */
.c-contact:hover .c-contact__icon {
  transform: translateY(-25%);
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-schedule.scss */
.c-schedule {
  display: inline-block;
  vertical-align: top;
  margin: 1em;
}

/* line 6, public/wp-content/themes/carcasse/assets/src/scss/component/_c-schedule.scss */
.c-schedule__day {
  background: #000000;
  border-radius: 100%;
  font-family: "oswald-regular";
  width: 54px;
  height: 54px;
  color: #FFFFFF;
  line-height: 54px;
  margin: 0 auto 1rem auto;
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.65);
}

/* line 18, public/wp-content/themes/carcasse/assets/src/scss/component/_c-schedule.scss */
.c-schedule__time {
  font-family: "oswald-light";
}

/* line 22, public/wp-content/themes/carcasse/assets/src/scss/component/_c-schedule.scss */
.c-schedule p {
  line-height: 1.5;
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-resto.scss */
.c-resto {
  padding-left: 9em;
}

@media (max-width: 839px) {
  /* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-resto.scss */
  .c-resto {
    padding-left: 0;
  }
}

/* line 8, public/wp-content/themes/carcasse/assets/src/scss/component/_c-resto.scss */
.c-resto__description {
  padding-top: 3em;
  padding-left: 6em;
  color: #FFFFFF;
}

@media (max-width: 839px) {
  /* line 8, public/wp-content/themes/carcasse/assets/src/scss/component/_c-resto.scss */
  .c-resto__description {
    padding-left: 0;
  }
}

/* line 1, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease;
}

/* line 14, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container.active {
  opacity: 1;
  pointer-events: auto;
}

/* line 19, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: -1;
  pointer-events: none;
}

/* line 32, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container .c-popup {
  width: 100%;
  max-width: 800px;
  z-index: 100;
  background-color: #000000;
  padding: 50px;
  border-radius: 10px;
  color: white;
  position: relative;
}

@media screen and (max-width: 767px) {
  /* line 32, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
  .c-popup--container .c-popup {
    max-height: 80vh;
    overflow: auto;
    padding: 50px 30px;
  }
}

/* line 48, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container .c-popup button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #E91F0A;
}

/* line 59, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
.c-popup--container .c-popup p {
  font-size: 2rem;
  margin: 0;
}

@media screen and (max-width: 767px) {
  /* line 59, public/wp-content/themes/carcasse/assets/src/scss/component/_c-popup.scss */
  .c-popup--container .c-popup p {
    font-size: 1.75rem;
  }
}
