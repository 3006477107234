html {
	font-size: $font-size-base;
}

html, body {
	height: 100%;
}

body {
	color: $color-text;
	margin: 0;
	background-color: $color-background;
	line-height: $line-height-base;
	font-family: $font-stack;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	transition: color .15s;
	color: $color-link;

	&:hover {
		color: $color-white;
	}
}

p {
	margin: 0;
	line-height: $line-height-paragraph;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

img {
	max-width: 100%;
}

textarea {
	resize: vertical;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1;
	text-transform: uppercase;
	color: $color-white;
	font-family: $font-stack-alt;
	font-weight: normal;
	margin: 0;
}

strong {
	font-family: $quattrocento-bold;
	font-weight: normal;
}

// Selection
::-moz-selection {
	background: $color-black;
	color: white;
}

::selection {
	background: $color-black;
	color: $color-white;
}
