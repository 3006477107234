.c-header {
  padding: 3em 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $z-index-3;

  &:before {
    content: '';
    background: linear-gradient(to bottom, rgba($color-black,.9) 0%, rgba($color-black,0) 100%);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 150%;
    transition: all 600ms linear;
    opacity: .5;
    pointer-events: none;
  }

  &--fixed {
    .c-navigation:not(.c-navigation--mobile) {
      transform: translateY(-2em);
      transition: transform 500ms linear;
    }

    &:before {
      opacity: 1;
    }
  }

  &--fixed &__logo {
    background-image: url('../images/svg/logo-compact.svg');
  }

  &__logo {
    width: 127px;
    height: 95px;
    margin: 0 4em 1em 4em;
    background-image: url('../images/svg/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;

    @include mq('sm') {
      width: 100px;
      margin: 0 !important;
    }
  }

  .c-navigation__social a:last-of-type {
    margin-right: 0;
  }
}
