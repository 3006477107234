.c-popup--container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease;

    &.active {
    	opacity: 1;
    	pointer-events: auto;
    }

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		opacity: 0.5;
		z-index: -1;
		pointer-events: none;
	}

	.c-popup {
		width: 100%;
		max-width: 800px;
		z-index: 100;
		background-color: $color-black;
		padding: 50px;
		border-radius: 10px;
		color: white;
		position: relative;

		@media screen and (max-width: 767px) {
			max-height: 80vh;
			overflow: auto;
			padding: 50px 30px;
		}

		button {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: transparent;
			border: 0;
			font-size: 2.5rem;
			font-weight: bold;
			color: $color-red;
		}

		p {
			font-size: 2rem;
			margin: 0;

			@media screen and (max-width: 767px) {
				font-size: 1.75rem;
			}
		}
	}
}