/* =======================================================================
Navigation
========================================================================== */

.nav {
  a {
    color: $color-white;
    text-decoration: none;
  }
}
