@each $mq in map-keys($breakpoints) {
  .hide {
    display: none !important;;
  }

  @include mq($mq) {
    .#{$mq}-hide {
      display: none !important;
    }
  }
}
