.c-menu {
  padding: 0 1em;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @include mq('sm') {
    padding: 0;
  }

  & + & {
    @include mq('sm') {
      margin-top: 1.5em;

      h4 {
        margin-top: 1.5em;
      }
    }
  }

  li + li {
    margin-top: 2em;
  }

  & > .button {
    margin-top: 2rem;
  }
}
