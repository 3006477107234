.c-schedule {
  display: inline-block;
  vertical-align: top;
  margin: 1em;

  &__day {
    background: $color-black;
    border-radius: 100%;
    font-family: $oswald;
    width: 54px;
    height: 54px;
    color: $color-white;
    line-height: 54px;
    margin: 0 auto 1rem auto;
    box-shadow: 0 -2px 20px 0 rgba($color-black, .65);
  }

  &__time {
    font-family: $oswald-light;
  }

  p {
    line-height: 1.5;
  }
}
