.c-promotions {
  display: flex;
  text-align: center;

  &__item {
    height: 570px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3em;

    &-description {
      opacity: 0;
      font-size: 0;
      transition: opacity .25s, font-size .25s .25s;

      @include mq('md') {
        opacity: 1;
        font-size: 100%;
      }
    }

    &:hover &-description {
      opacity: 1;
      font-size: 100%;
      transition: font-size .25s, opacity .5s .25s;
    }

    @include mq('md') {
      padding: 5em 3em;
      height: auto;
    }
  }

  @include mq('md') {
    flex-direction: column;
  }
}
