.icon {
  fill: $color-white;
  fill-opacity: .1;

  &--knife {
    width: 5em;
    display: block;
  }

  &--fork {
    vertical-align: top;
    width: 2em;
    margin: -.25em .5em 0 .5em;

    @include mq('sm') {
      width: 1.5em;
      margin: -.25em .25em 0;
    }
  }
}
