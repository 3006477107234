.c-title {
	position: relative;

	&--top .text-inclined {
		color: $color-white;
	}

	&--bottom .text-inclined {
		margin-top: 32px;
		transform: unset;
		color: $color-white;
	}

	&--resto .text-inclined {
		margin-left: -2.5em;

		@include mq("sm") {
			margin-left: 0;
		}
	}

	&--menu {
		margin-bottom: 3em;

		.text-inclined {
			margin-left: -2em;
		}
	}

	&--contact .text-inclined {
		margin-left: -6em;

		@include mq("sm") {
			margin-left: -3em;
		}
	}
}
