.c-resto {
  padding-left: 9em;

  @include mq('sm') {
    padding-left: 0;
  }

  &__description {
    padding-top: 3em;
    padding-left: 6em;
    color: $color-white;

    @include mq('sm') {
      padding-left: 0;
    }
  }
}
