/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xs': 	(max-width: 479px),
	'sm': 	(max-width: 839px),
	'md': 	(max-width: 992px),
	'lg': 	(max-width: 1199px),
	'xl': 	(max-width: 1440px)
);


/* =======================================================================
Font-face
========================================================================== */

@import url("//hello.myfonts.net/count/3a2059");

  
@font-face {
	font-family: 'braxton-regular';
	src: url('../fonts/braxton/3A2059_0_0.eot');
	src: url('../fonts/braxton/3A2059_0_0.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/braxton/3A2059_0_0.woff2') format('woff2'),
		 url('../fonts/braxton/3A2059_0_0.woff') format('woff'),
		 url('../fonts/braxton/3A2059_0_0.ttf') format('truetype');
	}


@font-face {
	font-family: 'oswald-regular';
	src: url('../fonts/oswald/oswald-regular.ttf') format("truetype"),
		 	 url('../fonts/oswald/oswald-regular.woff') format("woff"),
		 	 url('../fonts/oswald/oswald-regular.woff2') format("woff2"),
		 	 url('../fonts/oswald/oswald-regular.eot#iefix');
}

@font-face {
	font-family: 'oswald-light';
	src: url('../fonts/oswald/oswald-light.ttf') format("truetype"),
		 	 url('../fonts/oswald/oswald-light.woff') format("woff"),
		 	 url('../fonts/oswald/oswald-light.woff2') format("woff2"),
		 	 url('../fonts/oswald/oswald-light.eot#iefix');
}

@font-face {
	font-family: 'quattrocento-regular';
	src: url('../fonts/quattrocento/quattrocento-regular.ttf') format("truetype"),
		 	 url('../fonts/quattrocento/quattrocento-regular.woff') format("woff"),
		 	 url('../fonts/quattrocento/quattrocento-regular.woff2') format("woff2"),
		 	 url('../fonts/quattrocento/quattrocento-regular.eot#iefix');
}

@font-face {
	font-family: 'quattrocento-bold';
	src: url('../fonts/quattrocento/quattrocento-bold.ttf') format("truetype"),
		 	 url('../fonts/quattrocento/quattrocento-bold.woff') format("woff"),
		 	 url('../fonts/quattrocento/quattrocento-bold.woff2') format("woff2"),
		 	 url('../fonts/quattrocento/quattrocento-bold.eot#iefix');
}

/* @font-face {
	font-family: 'braxton-regular';
	src: url('../fonts/braxton/braxton-regular.ttf') format("truetype"),
		 	 url('../fonts/braxton/braxton-regular.woff') format("woff"),
		 	 url('../fonts/braxton/braxton-regular.woff2') format("woff2"),
		 	 url('../fonts/braxton/braxton-regular.eot#iefix');
} */


/* =======================================================================
Variables
========================================================================== */

// Colors
$color-black: 					#000000;
$color-white: 					#FFFFFF;
$color-gray:  					#B3B2B8;
$color-red: 						#E91F0A;

$color-text: 						$color-gray;
$color-link: 						$color-white;
$color-background:			$color-black;

$color-border: 					rgba($color-white, .12);

// Fonts
$oswald: 								'oswald-regular';
$oswald-light: 					'oswald-light';
$quattrocento: 					'quattrocento-regular';
$quattrocento-bold: 		'quattrocento-bold';
$braxton: 							'braxton-regular';

$font-stack:            $quattrocento, Georgia, serif;
$font-stack-alt:        $oswald, Arial, Verdana, sans-serif;
$font-stack-alt-2:      $braxton, Georgia, serif;

$font-size-1:						100px; // braxton
$font-size-2:						86px;
$font-size-3:						60px; // braxton
$font-size-4: 					54px;
$font-size-5: 					50px; // braxton
$font-size-6: 					30px;
$font-size-7: 					45px; // braxton
$font-size-8: 					18px;
$font-size-9: 					14px;
$font-size-10: 					11px;

$font-size-base: 				$font-size-9;

$line-height-base: 			1.4;
$line-height-paragraph:	2;

$max-width-1: 					1140px;
$max-width-2: 					1080px;
$max-width-3: 					880px;
$max-width-4: 					680px;

// Z-index
$z-index-1:							100;
$z-index-2:							200;
$z-index-3:							300;

// Spacing
$spacing-1: 						.5em;
$spacing-2: 						1em;
$spacing-3: 						2em;
$spacing-4: 						6.5em;
$spacing-5: 						10em;

// Transitions
$transition-easing:			cubic-bezier(0.645, 0.045, 0.355, 1);
$transition-duration: 	150ms;
