.c-intro {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .c-title {
    margin-bottom: 4rem;

    h1 {
      margin: 0;
    }

    h2 {
      margin-top: -0.4em;
    }

    p {
      width: 90%;
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
      font-size: 1.75rem;
      color: white;
      line-height: 1.2;
      margin-top: 2rem;
    }
  }

  .c-intro__button-col {
    display: flex;
    justify-content: space-evenly;
    gap: 60px;

    @media (max-width: 840px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}
