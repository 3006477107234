.button {
  padding: .85em 1.6em;
  margin: .5em 0;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
	font-family: $font-stack-alt;
	text-transform: uppercase;
  letter-spacing: 1px;
  transition: all $transition-duration $transition-easing;

  &:before, &:after {
    content: '';
    border: 1px solid currentColor;
    position: absolute;
    width: 100%;
    height: 30%;
    left: 0;
    right: 0;
    box-sizing: border-box;
    transition: inherit;
  }

  &:before {
    border-bottom: 0;
    top: 0;
  }

  &:after {
    border-top: 0;
    bottom: 0;
  }

  &-icon--left, &-icon--right {
    height: 3em;
    fill: currentColor;
    position: absolute;
    top: 50%;
    transition: inherit;
  }

  &-icon--left {
    left: 0;
    transform: translate(-80%, -50%);
  }

  &-icon--right {
    right: 0;
    transform: translate(80%, -50%);
  }

  &:hover &-icon--left {
    transform: translate(-120%, -50%);
  }

  &:hover &-icon--right {
    transform: translate(120%, -50%);
  }

  &:hover {
    &:before, &:after {
      height: 50%;
    }
  }
}
