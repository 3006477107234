.separator {
  background-image: url('../images/separator-section.png');
  width: 100%;
  height: 5px;
  margin: 5rem 0;
  background-repeat: no-repeat;
  background-position: center;

  @extend .h-clearfix;

  @include mq('sm') {
    margin: 2rem 0;
  }
}
